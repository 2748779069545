const { default: deposit } = require("@/mixins/deposit")

const bankInfoDetails = {
  bank_name(currency) {
    switch (currency) {
      case 'USD':
      case 'EUR':
      case 'CAD':
      case 'AUD':
      case 'JPY':
      case 'NZD':
        return 'Alphatick Pty Ltd Client'
      default: //GBP
        return 'Alphatick Pty Ltd Client'
    }
  },
  IBAN(currency) {
    switch (currency) {
      case 'USD':
      case 'EUR':
      case 'CAD':
      case 'AUD':
      case 'JPY':
      case 'NZD':
        return 'GB25SPPV23188485516721'
      default: //GBP
        return 'GB25SPPV23188485516721'
    }
  },
  IBAN(currency) {
    switch (currency) {
      case 'USD':
      case 'EUR':
      case 'CAD':
      case 'AUD':
      case 'JPY':
      case 'NZD':
        return 'GB25SPPV23188485516721'
      default: //GBP
        return 'GB25SPPV23188485516721'
    }
  },
  swift(currency) {
    switch (currency) {
      case 'USD':
      case 'EUR':
      case 'CAD':
      case 'AUD':
      case 'JPY':
      case 'NZD':
        return 'SPPVGB2L'
      default: //GBP
        return 'SPPVGB2L'
    }
  },
  account_name(currency) {
    switch (currency) {
      case 'USD':
      case 'EUR':
      case 'CAD':
      case 'AUD':
      case 'JPY':
      case 'NZD':
        return 'EQUALS MONEY UK'
      default: //GBP
        return 'EQUALS MONEY UK'
    }
  },
  beneficiary_address(currency) {
    switch (currency) {
      case 'USD':
      case 'EUR':
      case 'CAD':
      case 'AUD':
      case 'JPY':
      case 'NZD':
        return 'Vintners Place, 68 Upper Thames St, London'
      default: //GBP
        return 'Vintners Place, 68 Upper Thames St, London'
    }
  },
  bene_acc_name(currency) {
    switch (currency) {
      case 'GBP':
        return '85516721'
      default: //GBP
        return '85516721'
    }
  },


}
function bank_infos(currency) {
  console.log(51, currency)
  let bank_infos = []
  bank_infos.push({
    title: 'common.field.beneAccName',
    info: bankInfoDetails.bank_name(currency),
  })
  bank_infos.push({
    title: 'common.field.benIBAN',
    info: bankInfoDetails.IBAN(currency),
  })
  bank_infos.push({
    title: 'common.field.benSwiftCode',
    info: bankInfoDetails.swift(currency),
  })
  bank_infos.push({
    title: 'common.field.benBank',
    info: bankInfoDetails.account_name(currency),
  })
  bank_infos.push({
    title: 'common.field.benBankAddress',
    info: bankInfoDetails.beneficiary_address(currency),
  })
  if (currency === 'GBP') {
    bank_infos.push({
      title: 'common.field.beneAccNum',
      info: bankInfoDetails.bene_acc_name(currency),
    })
  }

  return bank_infos
}
export default {
  bank_infos
}
