<template>
  <div id="internationalSwift">
    <div class="content_box">
      <div class="inner">
        <div class="content_title">
          <h2>{{ $t('deposit.default.deposit') }}</h2>
          <p></p>
        </div>
        <div class="form_internationalAndAu" v-if="formFlag">
          <strong>{{ $t('deposit.intSwift.header') }}</strong>
          <el-form :model="internationalForm" ref="internationalForm" :rules="internationalRules">
            <div class="box">
              <strong class="title">
                <span>1</span>
                {{ $t('deposit.default.deposit') }}
              </strong>
              <div class="form_content_box">
                <div class="info">
                  <p>{{ $t('deposit.intSwift.desc', { platform: $config.info.fullName }) }}</p>
                  <p>{{ $t('deposit.intSwift.options') }}</p>
                </div>
                <ul class="clearfix">
                  <li>
                    <AccountNumber
                      supportedCurrencies="all"
                      @setAccountNumber="setAccountNumber"
                      @setCurrency="setCurrency"
                    ></AccountNumber>
                  </li>
                </ul>

                <ul class="bank_info clearfix" v-show="isShow">
                  <li v-bind:key="item.title" v-for="item in bank_infos">
                    <span class="title_name">{{ $t(item.title) }}</span>
                    <span class="value_info">{{ item.info }}</span>
                  </li>
                </ul>

                <div class="warn_info">
                  <p>{{ $t('deposit.intSwift.refInfo', { platform: $config.info.fullName }) }}</p>
                </div>
              </div>
            </div>
            <div class="box">
              <strong class="title">
                <span>2</span>
                {{ $t('deposit.default.sendReceipt') }}
              </strong>
              <div class="form_content_box">
                <div class="info">
                  <p>{{ $t('deposit.intSwift.desc2') }}</p>
                  <p>{{ $t('deposit.intSwift.bussDayInfo') }}</p>
                </div>

                <ul class="clearfix">
                  <li>
                    <InputForm
                      :label="$t('common.field.accNum')"
                      name="accNum"
                      :disabled="true"
                      v-model="internationalForm.accountNumber"
                    ></InputForm>
                  </li>
                  <li>
                    <numeric-input
                      v-model="internationalForm.amount"
                      :precision="2"
                      :label="$t('common.field.amt')"
                      name="amount"
                    ></numeric-input>
                  </li>
                </ul>
                <ul class="clearfix">
                  <li>
                    <el-form-item prop="uploadFile">
                      <vUpload
                        :limit="6"
                        :selfText="true"
                        v-on:updateFileInfo="updateFileInfo"
                        data-testid="updateFileInfo"
                      ></vUpload>
                    </el-form-item>
                  </li>
                  <li>
                    <InputForm
                      :label="$t('common.field.notes')"
                      name="applicationNotes"
                      v-model="internationalForm.notes"
                    ></InputForm>
                  </li>
                </ul>

                <div class="form_button">
                  <el-button class="purple_button" :loading="loading" @click="submitForm()" data-testid="submit">
                    {{ $t('common.button.submit') }}
                  </el-button>
                </div>
              </div>
            </div>
          </el-form>
        </div>
        <Result v-if="!formFlag">{{ $t('deposit.default.successMsg') }}</Result>
      </div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import vUpload from '@/components/vUpload';
import Result from '@/components/Result';
import mixin from '@/mixins/page/deposit';
import { apiInternational_bank_payment } from '@/resource';
import ibtBankInfo from '@/bankInfo/ibtBankInfo';
export default {
  name: 'internationalSwift',
  components: { NumericInput, AccountNumber, vUpload, Result },
  mixins: [mixin],
  data() {
    const checkUploadFile = (rule, value, callback) => {
      if (!(this.fileList.length > 0)) callback(new Error(this.$t('common.formValidation.fileReceipt')));
      else callback();
    };
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', {
              maxLimit: this.maxLimit,
              currency: this.accountCurrency
            })
          )
        );
      } else {
        callback();
      }
    };
    return {
      internationalForm: {
        account: '',
        accountNumber: '',
        amount: '',
        notes: ''
      },
      internationalRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        uploadFile: [
          {
            validator: checkUploadFile,
            required: true,
            trigger: 'change'
          }
        ]
      },
      fileList: [],
      isShow: false,
      formFlag: true
    };
  },
  computed: {
    countryCode() {
      return this.$store.state.common.countryCode;
    },
    bank_infos() {
      return ibtBankInfo.bank_infos(this.accountCurrency);
    }
  },
  watch: {
    accountCurrency() {
      if (this.internationalForm.amount !== 0) this.$refs.internationalForm.validateField('amount');
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.internationalForm.accountNumber = accountNumber;
      this.isShow = true;
    },
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['internationalForm'].validateField('uploadFile');
    },
    submitForm() {
      this.$refs['internationalForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.data) {
                this.formFlag = false;
              } else {
                this.loading = false;
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiInternational_bank_payment(
        {
          mt4Account: this.internationalForm.accountNumber,
          operateAmount: this.internationalForm.amount,
          applicationNotes: this.internationalForm.notes,
          currency: this.accountCurrency,
          fileList: this.fileList
        },
        this.token
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/internationalAndAu.scss';
</style>
